.editor-container {
  position: relative;

  .Placeholder__root {
    font-size: 14px;
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    left: 0;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    display: inline-block;
    pointer-events: none;
  }

  .editor-scroller {
    outline: 0;
    position: relative;

    .editor {
      position: relative;

      .ContentEditable__root {
        outline: 0;
        position: relative;
        font-size: 16px;
        line-height: 1.8;

        h3,
        h4 {
          margin-bottom: 16px;
        }

        h3+h4 {
          margin-top: -8px;
        }

        p {
          margin-bottom: 12px;
        }

        &> :first-child:is(h3) {
          font-size: 42px;
          font-weight: 400;

          &:has(br):not(:has(span))::before {
            position: absolute;
            content: "Title";
            color: #b3b3b1;
            cursor: text;
          }

          &+h4 {
            font-size: 28px;
            color: rgba(0, 0, 0, .54);
            font-weight: 300;

            &:has(br):not(:has(span))::before {
              position: absolute;
              content: "Subtitle";
              color: #b3b3b1;
              cursor: text;
            }

            &+p:has(br):not(:has(span)):not(:has(+p))::before {
              position: absolute;
              content: "Tell your story...";
              color: #b3b3b1;
              cursor: text;
            }
          }

          &+p:has(br):not(:has(span)):not(:has(+p))::before {
            position: absolute;
            content: "Tell your story...";
            color: #b3b3b1;
            cursor: text;
          }
        }
      }
    }
  }
}

i.palette {
  background-image: url(images/icons/palette.svg);
}

i.bucket {
  background-image: url(images/icons/paint-bucket.svg);
}

i.clear {
  background-image: url(images/icons/trash.svg);
}

i.code {
  background-image: url(images/icons/code.svg);
}

i.underline {
  background-image: url(images/icons/type-underline.svg);
}

i.strikethrough {
  background-image: url(images/icons/type-strikethrough.svg);
}

i.subscript {
  background-image: url(images/icons/type-subscript.svg);
}

i.superscript {
  background-image: url(images/icons/type-superscript.svg);
}

i.link {
  background-image: url(images/icons/link.svg);
}

i.horizontal-rule {
  background-image: url(images/icons/horizontal-rule.svg);
}

.icon.plus {
  background-image: url(images/icons/plus.svg);
}

.icon.caret-right {
  background-image: url(images/icons/caret-right-fill.svg);
}

.icon.dropdown-more {
  background-image: url(images/icons/dropdown-more.svg);
}

.icon.font-color {
  background-image: url(images/icons/font-color.svg);
}

.icon.font-family {
  background-image: url(images/icons/font-family.svg);
}

.icon.bg-color {
  background-image: url(images/icons/bg-color.svg);
}

i.image {
  background-image: url(images/icons/file-image.svg);
}

i.table {
  background-image: url(images/icons/table.svg);
}

i.close {
  background-image: url(images/icons/close.svg);
}

i.figma {
  background-image: url(images/icons/figma.svg);
}

i.poll {
  background-image: url(images/icons/card-checklist.svg);
}

i.columns {
  background-image: url(images/icons/3-columns.svg);
}

i.tweet {
  background-image: url(images/icons/tweet.svg);
}

i.youtube {
  background-image: url(images/icons/youtube.svg);
}

.icon.left-align,
i.left-align {
  background-image: url(images/icons/text-left.svg);
}

.icon.center-align,
i.center-align {
  background-image: url(images/icons/text-center.svg);
}

.icon.right-align,
i.right-align {
  background-image: url(images/icons/text-right.svg);
}

.icon.justify-align,
i.justify-align {
  background-image: url(images/icons/justify.svg);
}

i.indent {
  background-image: url(images/icons/indent.svg);
}

i.markdown {
  background-image: url(images/icons/markdown.svg);
}

i.outdent {
  background-image: url(images/icons/outdent.svg);
}

i.undo {
  background-image: url(images/icons/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(images/icons/arrow-clockwise.svg);
}

i.sticky {
  background-image: url(images/icons/sticky.svg);
}

i.mic {
  background-image: url(images/icons/mic.svg);
}

i.import {
  background-image: url(images/icons/upload.svg);
}

i.export {
  background-image: url(images/icons/download.svg);
}

i.share {
  background-image: url(images/icons/send.svg);
}

i.diagram-2 {
  background-image: url(images/icons/diagram-2.svg);
}

i.user {
  background-image: url(images/icons/user.svg);
}

i.equation {
  background-image: url(images/icons/plus-slash-minus.svg);
}

i.gif {
  background-image: url(images/icons/filetype-gif.svg);
}

i.copy {
  background-image: url(images/icons/copy.svg);
}

i.success {
  background-image: url(images/icons/success.svg);
}

i.prettier {
  background-image: url(images/icons/prettier.svg);
}

i.prettier-error {
  background-image: url(images/icons/prettier-error.svg);
}

i.page-break,
.icon.page-break {
  background-image: url(images/icons/scissors.svg);
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: fixed;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
