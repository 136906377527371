.nav-bar{
  display:block !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.nav-container {
  z-index: 1;
  padding: 0 32px;

  a.brand {
    display: flex;

    img {
      width: 146px;
    }
  }

  .menu {
    display: flex;

    .home {
      margin-right: 32px;
      font-size: 13px;
      height: 22px;
      color: #5f6368;
      margin-top: 22px;
      margin-bottom: 22px;
      cursor: pointer;
    }

    .favorites {
      margin-right: 32px;
      font-size: 13px;
      height: 20px;
      color: #5f6368;
      margin-top: 22px;
      margin-bottom: 22px;
      cursor: pointer;
    }
  }

  .icon {
    margin-right: 32px;
    margin-top: 17px;
    margin-bottom: 17px;
    cursor: pointer;
  }

  .user {
    width: 36px;
    height: 36px;
    // background-color: #242424;
    border-radius: 50%;
    color: white;
    margin-top: 14px;
    margin-bottom: 14px;
    cursor: pointer;

    .initial {
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.59px;
      height: 20px;
      margin-bottom: 2px;
    }

    .user_icon {
      text-align: center;
    }
  }

  .sideIcon {
    height: 18px !important;
    margin-right: 4px !important;
    width: 18px !important;
    margin-top: -2px !important;
  }

  .MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    left: 1220px !important;
  }

  .MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    margin-top: 8px !important;
  }

  .top {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-right: 32px;
  }
}
