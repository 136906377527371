@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Ubuntu',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c0c3c7;
}

// often reused classes
.justify-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

h1 {
  margin: 0;
}

.row {
  display: flex;
  column-gap: 8px;
  justify-content: space-between;

  &.head {
    justify-content: space-between;
  }
}

.custom-table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .action {
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .head {
    margin-bottom: 16px;
  }

  .nav-header {
    margin-bottom: 0;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ql-container {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Ubuntu',
    sans-serif;
  font-size: 14px;

  .ql-editor {
    line-height: 1.5;

    p:not(:first-child) {
      margin-top: 16px;
    }

    h4 {
      margin-top: 20px;
    }

    ol,
    ul {
      padding-left: 16px;
      margin-top: 16px;
      line-height: 2;
    }
  }
}

.ql-editor.ql-blank::before {
  font-style: normal;
  color: #969696;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.MuiPopper-root-MuiTooltip-popper {
  background-color: #2a3250;
  border-radius: 4px ;
  padding: 4px 0;
}

.MuiAutocomplete-root .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 0;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
.linear-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 338px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
p.PlaygroundEditorTheme__paragraph img {
  max-width: 100%;
  display: flex;
  margin: 1px auto;
}
