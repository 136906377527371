
//dropdown
.MuiMenuItem-root{
  font-family: Poppins !important;
  font-size: 13px !important;;
}

//tooltip
.MuiTooltip-tooltip{
  background-color: unset !important;
  margin: 0px !important;
  padding: 0px 8px !important;
  font-family: Poppins !important;
}
.MuiTooltip-arrow{
  color: #2a3250 !important;
}

label{
  font-size: 14px;
}

.MuiAutocomplete-root{
  width: 100%;
}

.MuiFilledInput-root{
  background-color: white !important;
  padding-top: 0px !important;
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  padding-left: 0px !important;
}

.MuiFilledInput-root::before{
  border-bottom: none !important;
}

.MuiFilledInput-root::after{
  border-bottom: none !important;
}

.MuiChip-root{
  font-family: 'Poppins' !important;
  font-size: 14px !important;
}

.MuiAutocomplete-tag{
  border-radius: 4px !important;
}

.MuiAutocomplete-endAdornment{
  display: none;
}

