.page_path {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 13px;
  color: #242424;
}

.page_path .title {
  font-weight: 400;
  color: #949494;
  cursor: pointer;
}


.post_commentsSection {
  padding: 0px 20px;
  max-width: 600px;
 .post_commentsSectionDetails {
   height: fit-content;
   margin: 2px 0 20px;
   font-family: Poppins;
   .post_details {
     font-family: Poppins;
     font-size: 15px;
     line-height: 1.6;
     text-align: left;
   }
 }

 .post_commentsSectionSend {
   margin: 20px 0 0;
   border-radius: 8px;
   justify-content: space-between;
   .textarea {
     .MuiInputBase-root-MuiInput-root:before {
       border: none;
     }
     .tops {
       height: 94px;
       padding: 8px 132px 8px 16px;
       border-radius: 8px;
       box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.19);
       font-family: Poppins;
     }
   }
   .post_btn {
     display: flex;
     .send_btn {
       height: 48px;
       margin: 0 0 48px 18px;
       padding: 12px 32px 12px 30px;
       border-radius: 8px;
       background-color: #006cfb;
       font-family: Poppins;
       font-family: 600;
       font-size: 16px;
     }
     .cancel_btn{
       height: 48px;
       margin: 0 0 48px 18px;
       padding: 12px 16px 12px 16px;;
       border-radius: 8px;
       font-family: Poppins;
       color: #242424;
       font-family: 600;
       font-size: 16px;
       &:hover {
         background-color: #f2f2f2;
         border-radius: 8px;
         color: #242424;
       }
     }
     
   }
 }

 .post_overall_content {
   overflow-y: auto;
   padding-right: 8px;
 }
}

.post_textarea {
  textarea {
    min-height: 100px;
    max-height: 100px;
    overflow-x: hidden;
    resize: none;
    border: 1px solid rgb(198, 197, 197);
    border-radius: 4px;
    font-family: 'Poppins';
    padding-left: 16px;
    padding-top: 10px;
  }
}

.MuiFormControl-root {
  width: 95%;
}
.MuiChip-label{
  font-size: 10px;
}
