.link-editor {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 8px 8px;
  transition: opacity 0.5s;
  will-change: transform;
}

.link-editor .button {
  width: 20px;
  height: 20px;
  display: inline-block;
  padding: 6px;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 2px;
}

.link-editor .button.hovered {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #eee;
}

.link-editor .button i,
.actions i {
  background-size: contain;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: -0.25em;
}

.link-editor div.link-edit {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-pencil-fill'%3e%3cpath%20d='M12.854.146a.5.5%200%200%200-.707%200L10.5%201.793%2014.207%205.5l1.647-1.646a.5.5%200%200%200%200-.708l-3-3zm.646%206.061L9.793%202.5%203.293%209H3.5a.5.5%200%200%201%20.5.5v.5h.5a.5.5%200%200%201%20.5.5v.5h.5a.5.5%200%200%201%20.5.5v.5h.5a.5.5%200%200%201%20.5.5v.207l6.5-6.5zm-7.468%207.468A.5.5%200%200%201%206%2013.5V13h-.5a.5.5%200%200%201-.5-.5V12h-.5a.5.5%200%200%201-.5-.5V11h-.5a.5.5%200%200%201-.5-.5V10h-.5a.499.499%200%200%201-.175-.032l-.179.178a.5.5%200%200%200-.11.168l-2%205a.5.5%200%200%200%20.65.65l5-2a.5.5%200%200%200%20.168-.11l.178-.178z'/%3e%3c/svg%3e");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -.25em;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  cursor: pointer
}

.link-editor div.link-trash {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='16'%20height='16'%20fill='currentColor'%20class='bi%20bi-trash'%3e%3cpath%20d='M5.5%205.5A.5.5%200%200%201%206%206v6a.5.5%200%200%201-1%200V6a.5.5%200%200%201%20.5-.5zm2.5%200a.5.5%200%200%201%20.5.5v6a.5.5%200%200%201-1%200V6a.5.5%200%200%201%20.5-.5zm3%20.5a.5.5%200%200%200-1%200v6a.5.5%200%200%200%201%200V6z'/%3e%3cpath%20fill-rule='evenodd'%20d='M14.5%203a1%201%200%200%201-1%201H13v9a2%202%200%200%201-2%202H5a2%202%200%200%201-2-2V4h-.5a1%201%200%200%201-1-1V2a1%201%200%200%201%201-1H6a1%201%200%200%201%201-1h2a1%201%200%200%201%201%201h3.5a1%201%200%200%201%201%201v1zM4.118%204%204%204.059V13a1%201%200%200%200%201%201h6a1%201%200%200%200%201-1V4.059L11.882%204H4.118zM2.5%203V2h11v1h-11z'/%3e%3c/svg%3e");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -.25em;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer
}

.link-editor div.link-cancel {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'%3e%3c!--!%20Font%20Awesome%20Pro%206.1.1%20by%20@fontawesome%20-%20https://fontawesome.com%20License%20-%20https://fontawesome.com/license%20(Commercial%20License)%20Copyright%202022%20Fonticons,%20Inc.--%3e%3cpath%20d='M0%20256C0%20114.6%20114.6%200%20256%200s256%20114.6%20256%20256-114.6%20256-256%20256S0%20397.4%200%20256zm175-47.9%2047.1%2047L175%20303c-9.3%209.4-9.3%2024.6%200%2033.1%209.4%2010.2%2024.6%2010.2%2033.1%200l47-46.2%2047.9%2046.2c9.4%2010.2%2024.6%2010.2%2033.1%200%2010.2-8.5%2010.2-23.7%200-33.1l-46.2-47.9%2046.2-47c10.2-8.5%2010.2-23.7%200-33.1-8.5-9.3-23.7-9.3-33.1%200l-47.9%2047.1-47-47.1c-8.5-9.3-23.7-9.3-33.1%200-9.3%209.4-9.3%2024.6%200%2033.1z'/%3e%3c/svg%3e");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -.25em;
  margin-right: 28px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer
}

.link-editor div.link-confirm {
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2050%2050'%3e%3ccircle%20cx='25'%20cy='25'%20r='25'%20fill='%23000'/%3e%3cpath%20fill='none'%20stroke='%23fff'%20stroke-width='4'%20stroke-linecap='round'%20stroke-linejoin='round'%20stroke-miterlimit='10'%20d='M38%2015%2022%2033l-10-8'/%3e%3c/svg%3e");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -.25em;
  margin-right: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer
}

.link-editor .link-input {
  display: block;
  width: calc(100% - 75px);
  box-sizing: border-box;
  margin: 12px;
  padding: 8px 12px;
  border-radius: 15px;
  background-color: #eee;
  font-size: 15px;
  color: #050505;
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit
}

.link-editor .link-view {
  display: block;
  width: calc(100% - 24px);
  margin: 8px 12px;
  padding: 8px 12px;
  border-radius: 15px;
  font-size: 15px;
  color: #050505;
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit
}

.link-editor .link-view a {
  display: block;
  word-break: break-word;
  width: calc(100% - 33px)
}

.link-editor .button.active,.toolbar .button.active {
  background-color: #dfe8fa
}

.link-editor .link-input a {
  color: #216fdb;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis
}

.link-editor .link-input a:hover {
  text-decoration: underline
}

.link-editor .font-size-wrapper,.link-editor .font-family-wrapper {
  display: flex;
  margin: 0 4px
}

.link-editor select {
  padding: 6px;
  border: none;
  background-color: #00000013;
  border-radius: 4px
}

