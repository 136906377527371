.create-landing {
  padding-left: 152px;
  padding-top: 40px;
  height: 87vh;

  .create-info {
    margin-bottom: 96px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
  }

  .description {
    font-size: 14px;
    font-weight: normal;
    color: #6e6e6e;
    font-style: italic;
  }

  .create-cards {
    display: flex;
    justify-content: space-between;
    width: 80%;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-right: 10px;
    }

    .MuiCard-root {
      margin-right: 60px;
      width: 144px;
      height: 112px;
      border-radius: 4px;
      box-shadow: 4px 4px 8px 3px rgba(0, 0, 0, 0.15);
      cursor: pointer;

      @media (max-width: 768px) {
        margin-right: 10;
        margin-bottom: 20px;
        width: calc(35%);
      }
      &:hover {
        background-color: #edf7ff;
      }
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  svg {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }

  .post-text {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 600;
  }
}
